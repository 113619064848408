import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'


export function resolveLinkObjectToHref(item) {
  if (!item) return null
  const isInternalLink = item?._type === 'internalLink'

  const href = isInternalLink && item.ref
    ? determineDocumentPathSync({ document: item.ref, routeMap })
    : item?.href

  return href
}

export function determineLinkProps({ href, targetSelf = false }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const openInNewTab = !targetSelf && hasProtocol

  const target = openInNewTab ? '_blank' : '_self'
  const rel = openInNewTab ? 'noopener noreferrer' : null

  return { openInNewTab, rel, target }
}
