import { useSpring, animated } from 'react-spring'
import { pseudoRandom } from '@kaliber/math'
import { useReportError } from '/machinery/ReportError'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { determineLinkProps, resolveLinkObjectToHref } from '/machinery/link'
import { routeMap } from '/routeMap'
import { makeSlug } from '/machinery/makeSlug'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'

import { Icon } from '/features/buildingBlocks/Icon'
import { Employee } from '/features/buildingBlocks/Employee'
import { ImageCover } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Tile.css'

import arrowIcon from '/images/icons/arrow.raw.svg'
import playIcon from '/images/icons/play.raw.svg'

export const availableTileStyleContexts = ['light', 'dark', 'blue-dark', 'green']

export function Tile({ tile, blankTile = false, availableStyleContexts = undefined }) {
  const { representation } = tile
  const reportError = useReportError()
  const { __ } = useTranslate()
  const language = useLanguage()

  switch (representation?._type) {
    case 'tileJob': {
      return (
        <TileJob
          title={representation.title}
          label={__`tile-type-job`}
          hours={__({ hours: representation.hours })`job-hours-max`}
          location={representation.city}
          {...{ blankTile }}
          href={routeMap.app.job({
            language,
            jobTitle: makeSlug({ input: tile.job_title, language: tile.job_language }),
            jobId: tile.job_id
          })}
        />
      )
    }

    case 'tileImage': {
      return (
        <TileImage
          label={representation.label ? __`tile-type-${representation.label}` : null}
          title={representation.title}
          image={representation.image}
          href={determineDocumentPathSync({ document: tile, routeMap })}
          {...{ blankTile }}
        />
      )
    }

    case 'tileExpertise': {
      return (
        <TileExpertise
          label={representation.label ? __`tile-type-${representation.label}` : null}
          title={representation.title}
          image={representation.image}
          href={determineDocumentPathSync({ document: tile, routeMap })}
          {...{ blankTile }}
        />
      )
    }

    case 'tileTechblog': {
      return (
        <TileTechblog
          label={representation.label ? __`tile-type-${representation.label}` : null}
          employee={representation.employee}
          title={representation.title}
          href={determineDocumentPathSync({ document: tile, routeMap })}
          {...{ blankTile }}
        />
      )
    }

    case 'tileVideo': {
      return (
        <TileVideo
          label={__`tile-type-video`}
          title={representation.title}
          image={representation.image}
          href={determineDocumentPathSync({ document: tile, routeMap })}
          {...{ blankTile }}
        />
      )
    }

    case 'tileQuote': return (
      <TileQuote
        label={representation.label ? __`tile-type-${representation.label}` : null}
        quote={representation.quote}
        employee={representation.employee}
        styleContext={representation.styleContext}
        href={resolveLinkObjectToHref(tile?.representation?.internalOrExternalLink)}
        {...{ blankTile, availableStyleContexts }}
      />
    )

    case 'tilePodcast': return (
      <TilePodcast
        label={__`tile-type-podcast`}
        title={representation.title}
        styleContext={representation.styleContext}
        {...{ blankTile, availableStyleContexts }}
      />
    )

    case 'tileStatistic': return (
      <TileStatistic
        label={__`tile-type-statistic`}
        statistic={representation.statistic}
        description={representation.description}
        styleContext={representation.styleContext}
        href={tile.slug && determineDocumentPathSync({ document: tile, routeMap })}
        {...{ blankTile, availableStyleContexts }}
      />
    )

    case 'tileLink': return (
      <TileLink
        title={representation.title}
        label={representation.label}
        targetSelf={representation.internalOrExternalLink?.targetSelf}
        href={resolveLinkObjectToHref(tile?.representation?.internalOrExternalLink)}
        {...{ blankTile, availableStyleContexts }}
      />
    )

    case 'tileSkillsmatch': return (
      <TileSkillsMatch
        title={representation.title}
        label={representation.label}
        image={representation.image}
        {...{ blankTile, availableStyleContexts }}
      />
    )

    default: {
      if (process.env.NODE_ENV === 'production') {
        reportError(new Error(`Unknown content type '${representation._type}'`))
        return null
      } else {
        return <div style={{ color: 'red', fontSize: '1rem' }}>Unknown content type '{representation?._type}'</div>
      }
    }
  }
}

export function TileJob({ title, label, location, hours, href, blankTile }) {
  return (
    <TileBase
      bottomSlot={
        <div className={styles.jobMetaContainer}>
          <span className={styles.jobMetaItem}>{location}</span>
          <span className={styles.jobMetaItem}>{hours}</span>
        </div>
      }
      dataX='link-to-job'
      styleContext='blue'
      className={styles.componentJob}
      {...{ title, label, href, blankTile }}
    />
  )
}

export function TileQuote({ quote, href, employee, label, blankTile, styleContext = undefined, availableStyleContexts = undefined  }) {
  const contexts = availableStyleContexts?.length ? availableStyleContexts : availableTileStyleContexts

  return (
    <TileBase
      className={styles.componentQuote}
      styleContext={styleContext ?? contexts.at(pseudoRandom(label) * contexts.length)}
      title={quote}
      dataX='link-to-article'
      bottomSlot={employee && (
        <Employee name={employee.title} image={employee.avatar} functionTitle={employee.functionTitle} />
      )}
      {...{ label, blankTile, href }}
    />
  )
}

export function TileStatistic({ statistic, description, label, href, blankTile, styleContext = undefined, availableStyleContexts = undefined }) {
  const contexts = availableStyleContexts?.length ? availableStyleContexts : availableTileStyleContexts

  return (
    <TileBase
      dataX='link-to-article'
      className={styles.componentStatistic}
      styleContext={styleContext ?? contexts.at(pseudoRandom(label) * contexts.length)}
      title={<span aria-label={statistic + ' ' + description}>{description}</span>}
      topSlot={<span className={styles.statistic} aria-hidden='true'>{statistic}</span>}
      {...{ label, blankTile, href }}
    />
  )
}

export function TileImage({ title, label, image, href, blankTile }) {
  return (
    <TileBase
      dataX='link-to-article'
      className={styles.componentImage}
      styleContext='dark'
      {...{ title, label, image, href, blankTile }}
    />
  )
}

export function TileExpertise({ title, label, image, href, blankTile }) {
  return (
    <TileBase
      dataX='link-to-expertise'
      className={styles.componentExpertise}
      styleContext='dark'
      {...{ title, label, image, href, blankTile }}
    />
  )
}

export function TileTechblog({ title, label, href, employee, blankTile }) {
  return (
    <TileBase
      dataX='link-to-techblog'
      className={styles.component_rootTechblog}
      styleContext='blue-dark'
      bottomSlot={employee && (
        <Employee name={employee.title} image={employee.avatar} functionTitle={employee.functionTitle} />
      )}
      {...{ title, label, href, blankTile }}
    />
  )
}

export function TileVideo({ title, label, image, href, blankTile }) {
  return (
    <TileBase
      className={styles.componentVideo}
      styleContext='dark'
      dataX='link-to-article'
      {...{ title, label, image, href, blankTile }}
    />
  )
}

export function TilePodcast({ title, label, styleContext = undefined, blankTile, availableStyleContexts = undefined }) {
  const contexts = availableStyleContexts?.length ? availableStyleContexts : availableTileStyleContexts

  return (
    <TileBase
      dataX='link-to-podcast'
      className={styles.componentPodcast}
      styleContext={styleContext ?? contexts.at(pseudoRandom(label) * contexts.length)}
      showPodcastIcon
      {...{ title, label, blankTile }}
    />
  )
}

export function TileSkillsMatch({ image, title, label, styleContext = undefined, blankTile, availableStyleContexts = undefined }) {
  const contexts = availableStyleContexts?.length ? availableStyleContexts : availableTileStyleContexts

  return (
    <TileBase
      dataX='link-to-skills-match'
      className={styles.componentSkillsMatch}
      styleContext={styleContext ?? contexts.at(pseudoRandom(label) * contexts.length)}
      {...{ title, label, blankTile, image }}
    />
  )
}

export function TileLink({ title, label, href, targetSelf, blankTile, styleContext = undefined, availableStyleContexts = undefined  }) {
  const contexts = availableStyleContexts?.length ? availableStyleContexts : availableTileStyleContexts

  return (
    <TileBase
      className={styles.componentLink}
      styleContext={styleContext ?? contexts.at(pseudoRandom(href) * contexts.length)}
      dataX='link'
      {...{ title, label, href, targetSelf, blankTile }}
    />
  )
}

function TileBase({
  className,
  title,
  label,
  blankTile,
  dataX,
  href = undefined,
  targetSelf = undefined,
  image = undefined,
  topSlot = undefined,
  bottomSlot = undefined,
  styleContext = undefined,
  showPodcastIcon = false
}) {
  const linkProps = href ? determineLinkProps({ href, targetSelf }) : null

  const isMounted = useRenderOnMount()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const responsiveAspectRatio = isViewportMd ? 3 / 4 : 16 / 9
  const opacitySpring = useSpring({
    opacity: blankTile ? 0 : 1
  })

  return (
    <div className={styles.componentBase} data-style-context={styleContext}>
      <div className={cx(styles.layout, className, blankTile && styles.isBlankTile)}>
        {<animated.div className={styles.heading} style={opacitySpring}>
          {<a {...{ href }} data-x={dataX} className={styles.title} rel={linkProps?.rel} target={linkProps?.target}>
            <span className={styles.lineClamp}>{title}</span>
          </a>}
        </animated.div>}

        {topSlot && <animated.div className={styles.topSlot} style={opacitySpring}>{topSlot}</animated.div>}
        {bottomSlot && <animated.div className={styles.bottomSlot} style={opacitySpring}>{bottomSlot}</animated.div>}
        {image && isMounted && (
          <div className={styles.image}>
            <ImageCover aspectRatio={responsiveAspectRatio} {...{ image }} />
          </div>
        )}

        <div className={styles.labelContainer}>
          {label && <animated.div className={styles.label} style={opacitySpring}>{label}</animated.div>}
          <div className={styles.arrowContainer}>
            <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
          </div>
        </div>

        {showPodcastIcon && <PodcastIcon />}
      </div>
    </div>
  )
}

function PodcastIcon() {
  return (
    <div className={styles.componentPodcastIcon}>
      <div className={styles.playIcon}>
        <Icon icon={playIcon} layoutClassName={styles.iconLayout} />
      </div>
      <div className={styles.podcastIcon} />
    </div>
  )
}
