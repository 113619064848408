import { HeadingLg } from '/features/buildingBlocks/Heading'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'

import styles from './IntractiveVideoIframe.css'

export function IntractiveVideoIframe({ iFrameLink, title = undefined, description = undefined, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName, (title || description) && styles.grid)}>
      {(title || description) && <Content {...{ title, description }} layoutClassName={styles.contentLayout} />}
      <IframeVideo {...{ iFrameLink, title }} layoutClassName={styles.iframeVideoLayout} />
    </div>
  )
}

function Content({ title, description, layoutClassName }) {
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      {title && (
        <span className={styles.title}>
          <HeadingLg h={2} {...{ title }} />
        </span>
      )}
      {description && <PortableTextMainContent value={description} layoutClassName={styles.contentLayout} />}
    </div>
  )
}

function IframeVideo({ iFrameLink, title, layoutClassName }) {
  return (
    <iframe
      className={cx(styles.componentIframeVideo, layoutClassName)}
      src={iFrameLink}
      sandbox='allow-scripts allow-same-origin'
      {...{ title }}
    />
  )
}
