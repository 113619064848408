import { flushSync } from 'react-dom'

export function determineLayoutByActiveVideo({ activeVideo, onChange }) {
  const isViewTransitionSupported = Boolean(document.startViewTransition)

  const toggleState = () => {
    flushSync(() => {
      onChange((prev) =>
        !prev ? activeVideo : null
      )
    })
  }

  if (isViewTransitionSupported && document.visibilityState === 'visible') {
    document.startViewTransition(toggleState)
  } else {
    toggleState()
  }
}
