import { determineLayoutByActiveVideo } from '/features/buildingBlocks/Homepage/determineLayoutByActiveVideo'
import { GridHome } from '/features/buildingBlocks/Homepage/GridHome'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useTranslate } from '/machinery/I18n'

import styles from './Hero.css'

export function Hero({ titleFirstPart, titleSecondPart, videoPortrait, videoLandscape, imageTiles, layoutClassName }) {
  const [activeVideo, setActiveVideo] = React.useState(null)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const videoTopRef = React.useRef(null)
  const videoBottomRef = React.useRef(null)

  React.useEffect(() => {
    !isInViewport && determineLayoutByActiveVideo({ activeVideo: null, onChange: setActiveVideo })
  }, [isInViewport])

  const isTopVideoActive = activeVideo === 'top'
  const isBottomVideoActive = activeVideo === 'bottom'
  const videoTiles = [videoPortrait, videoLandscape]

  return (
    <header
      onClick={handleClickOutsideRef}
      onKeyDown={handleKeyDown}
      ref={visibilityRef}
      data-style-context='light'
      data-x='hero-home'
      className={cx(styles.component, layoutClassName)}
    >
      <div className={styles.heroContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.layout}>
            {titleFirstPart && titleSecondPart && (
              <div className={styles.heading}>
                <HeroTitle {...{ titleFirstPart, titleSecondPart }} />
              </div>
            )}
            {Boolean(imageTiles?.length) && Boolean(videoTiles?.length) && (
              <GridHome
                ref={[videoTopRef, videoBottomRef]}
                layoutClassName={styles.gridHomeLayout}
                onSetVideoActiveChange={setActiveVideo}
                {...{
                  imageTiles,
                  videoTiles,
                  isBottomVideoActive,
                  isTopVideoActive
                }}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  )

  /** @param {import('react').KeyboardEvent} e */
  function handleKeyDown(e) {
    if  (e.key === 'Escape') {
      return determineLayoutByActiveVideo({ activeVideo: null, onChange: setActiveVideo })
    }
  }

  function handleClickOutsideRef(event) {
    const isNotVideo = event.target !== (videoTopRef.current || videoBottomRef.current)
    const containsVideoElement = event.target.contains(videoTopRef.current || videoBottomRef.current)

    if (isNotVideo && containsVideoElement) {
      return determineLayoutByActiveVideo({ activeVideo: null, onChange: setActiveVideo })
    }
  }
}

function HeroTitle({ titleFirstPart, titleSecondPart }) {
  const { __ } = useTranslate()
  return (
    <h1 aria-label={__`home-hero-title`} className={styles.componentTitle}>
      <span className={styles.titleFirstPart}>{` ${titleFirstPart} `}</span>
      <span className={styles.ampersand}>&</span>
      <span className={styles.titleSecondPart}>{` ${titleSecondPart} `}</span>
    </h1>
  )
}
