import { CardWithPermanentArrowIcon } from '/features/buildingBlocks/card/Card'
import { LabelsWithDate, Labels } from '/features/buildingBlocks/Labels'

import styles from './JobCard.css'

export function JobCard({ id, title, href, labels, branches = [], dateStart = undefined, smallTitle = false }) {
  return (
    <CardWithPermanentArrowIcon dataX='link-to-job' dataXContext={id} {...{ href }}>
      <div className={styles.component}>
        {branches && <Branches {...{ branches }} />}
        <h3 className={cx(styles.title, smallTitle && styles.titleSm)}>
          {title}
        </h3>
        <div className={styles.info}>
          {/*
          //TODO Add when skills are available
          <div className={styles.match}>
            <button
              data-style-context={hovered ? 'blue' : 'light-alt'}
              className={cx(styles.button, hovered && styles.hovered)}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            //TODO add onClick function if matching skills are available
            >
              <Icon icon={chevronIcon} />
            </button>
            <span>{__`skills-match`}</span>
          </div> */}
          <div className={styles.metadata}>
            {dateStart ? (
              <LabelsWithDate {...{ labels }} date={dateStart} />
            ) : (
              <Labels {...{ labels }} />
            )}
          </div>
        </div>
      </div>
    </CardWithPermanentArrowIcon>
  )
}

function Branches({ branches }) {
  return (
    <ul className={cx(styles.componentBranches)}>
      {branches.map((branch, i) => (
        <li key={i} className={styles.branch}>
          <span>{branch}</span>
        </li>
      ))}
    </ul>
  )
}
