import { useShowSkillsMatchPortalFlow } from '/features/pageOnly/skillsMatch/skillsMatchPortalFlowHooks'
import { ButtonPrimary, ButtonSecondary, ButtonWithIcon } from '/features/buildingBlocks/Button'

import styles from './ShowSkillsMatchPortalFlowButton.css'

/** @param {{ label: string, buttonType: 'primary' | 'secondary' | 'block' | 'withIcon', dataX: string, tabIndex?: 0 | -1, layoutClassName?: string }} props */
export function ShowSkillsMatchPortalFlowButton({ label, buttonType, dataX, tabIndex = 0, layoutClassName = undefined }) {
  const showSkillsMatchPortalFlow = useShowSkillsMatchPortalFlow()
  const ButtonComponent = getButtonComponent({ buttonType })

  return <ButtonComponent onClick={showSkillsMatchPortalFlow} {...{ label, dataX, tabIndex, layoutClassName }} />
}

function ButtonBlock({ dataX, label, onClick, tabIndex = 0, layoutClassName = undefined }) {
  return (
    <button
      type='button'
      className={cx(styles.componentButtonBlock, layoutClassName)}
      data-x={dataX}
      aria-label={label}
      {...{ onClick, tabIndex }}
    >
      {label ?? label}
    </button>
  )
}

function getButtonComponent({ buttonType }) {
  switch (buttonType) {
    case 'primary': return ButtonPrimary
    case 'secondary': return ButtonSecondary
    case 'block': return ButtonBlock
    case 'withIcon': return ButtonWithIcon
    default: throw new Error('Invalid button type passed in ShowSkillsMatchPortalFlowButton component. Use one of the following: \'primary\', \'secondary\' or \'block\'')
  }
}
