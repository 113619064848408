import { QueryStringProvider } from '@kaliber/use-query-string'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { JobsListAndFilter } from './JobsListAndFilter'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { Loader } from '/features/buildingBlocks/Loader'

import styles from './JobsListAndFilterApp.css'

const client = new QueryClient()

export default function JobsListAndFilterApp({
  initialJobs,
  totalJobsCount,
  title,
  filters,
  search,
  userCountry,
  benefitPageRef,
}) {
  const isMounted = useRenderOnMount()

  if (!isMounted) return (
    <div className={styles.component_root} data-style-context='light'>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )

  return (
    <QueryStringProvider {...{ search }}>
      <QueryClientProvider {...{ client }}>
        <JobsListAndFilter {...{ initialJobs, totalJobsCount, title, filters, userCountry, benefitPageRef }} />
      </QueryClientProvider>
    </QueryStringProvider>
  )
}
