import { asRouteMap } from '@kaliber/routing'
import groq from 'groq'
import { parameterExtraction, docWithSeo } from '@kaliber/sanity-routing/sanity'
import { cookieConfiguration } from '/machinery/tracking/cookieConfiguration'
import { snippets } from './groqSnippets'
import { toPlainText } from '@portabletext/react'
import { mapJobForDataLayer } from './machinery/tracking/pushToDataLayer'
import { translateSingular } from '/i18n/translations'
import { date, wordCount } from './machinery/tracking/metadata'
import { transformTaxonomyDataForDocument } from './domain/taxonomyData'

const { extract, slug, language } = parameterExtraction
export const JOBS_PER_PAGE = 20
export const TECHBLOGS_PER_PAGE = 8
export const TOPICS_PER_PAGE = 12

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        url: {
          path: 'document/path',
          data: requestHandlers => requestHandlers.determineDocumentPath,
        },

        getCandidateId: {
          path: 'getCandidateId',
          data: requestHandlers => requestHandlers.getCandidateId
        },

        cookieConfiguration: {
          path: 'cookieConfiguration',
          data: requestHandlers => requestHandlers.setCookieConfiguration,
        },

        jobs: {
          path: 'jobs',
          data: requestHandlers => requestHandlers.fetchJobs,
        },

        techblogs: {
          path: 'techblogs',
          data: requestHandlers => requestHandlers.fetchTechblogs,
        },

        topics: {
          path: 'topics',
          data: requestHandlers => requestHandlers.fetchTopics,
        },

        topicsSuggestions: {
          path: 'topicsSuggestions',
          data: requestHandlers => requestHandlers.topicsSuggestions,
        },

        topic: {
          path: 'topic',
          data: requestHandlers => requestHandlers.fetchTopic,
        },

        search: {
          path: 'search',
          data: requestHandlers => requestHandlers.search,
        },

        indeed: {
          path: 'vacatures-indeed.xml',
          data: requestHandlers => requestHandlers.xmlFeedIndeed
        },

        linkedin: {
          path: 'vacatures-linkedin.xml',
          data: requestHandlers => requestHandlers.xmlFeedLinkedin
        },

        vonq: {
          path: 'vacatures-vonq.xml',
          data: requestHandlers => requestHandlers.xmlFeedVonq
        },

        banken: {
          path: 'vacatures-banken.xml',
          data: requestHandlers => requestHandlers.xmlFeedBanken
        },

        skillsMatch: {
          path: 'skillsMatch',

          userSelection: {
            path: 'userSelection',
            data: requestHandlers => requestHandlers.skillsMatchUserSelection
          },

          introductionSeen: {
            path: 'introductionSeen',
            data: requestHandlers => requestHandlers.skillsMatchIntroductionSeen
          },

          markIntroductionSeen: {
            path: 'markIntroductionSeen',
            data: requestHandlers => requestHandlers.skillsMatchMarkIntroductionSeen
          },

          skillsData: {
            path: 'skillsData',
            data: requestHandlers => requestHandlers.skillsMatchSkillsData,
          },

          proofPointsData: {
            path: 'proofPointsData',
            data: requestHandlers => requestHandlers.skillsMatchProofPointsData,
          },

          toggleUserSelection: {
            path: 'toggleUserSelection',
            data: requestHandlers => requestHandlers.skillsMatchToggleUserSelection,
          },

          removeUserSelection: {
            path: 'removeUserSelection',
            data: requestHandlers => requestHandlers.skillsMatchRemoveUserSelection,
          },
        },

      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      redirectJob: {
        path: 'redirectJob',

        job: {
          path: ':jobId',
          data: requestHandlers => requestHandlers.redirectJob,
        }
      },

      notFound: '*'
    },
    preview: 'preview',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home.data,
      },
      article: {
        path: {
          nl: 'artikel/:slug',
          en: 'article/:slug'
        },
        data: routeData.app.article.data
      },
      jobAlert: {
        path: {
          en: 'job-alert',
          nl: 'jobalert'
        },
        index: {
          path: '',
          data: routeData.app.jobAlert.data,
        },
        verify: {
          path: 'subscription/verify',
          data: routeData.app.jobAlert.verify.data,
        },
        unsubscribe: {
          path: 'subscription/unsubscribe',
          data: routeData.app.jobAlert.unsubscribe.data,
        },
      },
      topics: {
        path: {
          en: 'the-world-around-you',
          nl: 'de-wereld-om-je-heen'
        },
        data: routeData.app.topics.data,

        detail: {
          path: ':slug',
          data: routeData.app.topics.detail.data,
        }
      },
      jobs: {
        path: {
          en: 'jobs',
          nl: 'vacatures'
        },
        data: routeData.app.jobs.data
      },
      job: {
        path: {
          en: 'job/:jobTitle/:jobId',
          nl: 'vacature/:jobTitle/:jobId',
        },
        data: routeData.app.job.data,
      },
      vakgebied: {
        path: {
          en: 'expertise/:vakgebiedTitle',
          nl: 'vakgebied/:vakgebiedTitle'
        },
        data: routeData.app.vakgebied.data,

        subvakgebied: {
          path: ':slug',
          data: routeData.app.vakgebied.subvakgebied.data,
        }
      },
      traineeship: {
        path: {
          en: 'traineeships',
          nl: 'traineeships',
        },

        index: {
          path: '',
          data: routeData.app.traineeship.data,
        },

        detail: {
          path: ':slug',
          data: routeData.app.traineeship.detail.data
        },
      },

      cookiePolicy: {
        path: {
          en: 'cookie-policy',
          nl: 'cookie-beleid'
        },
        data: routeData.app.cookiePolicy.data,
      },
      faq: {
        path: {
          en: 'faq',
          nl: 'veelgestelde-vragen'
        },
        data: routeData.app.faq.data,
      },
      page: {
        path: ':slug',
        data: routeData.app.page.data,
      },
      preboarding: {
        path: 'pre-boarding',
        data: routeData.app.preboarding.data,

        childPage: {
          path: ':slug',
          data: routeData.app.preboarding.childPage.data,
        }
      },
      techblog: {
        path: 'techblog',
        overview: {
          path: '',
          data: routeData.app.techblog.data,
        },
        detail: {
          path: ':slug',
          data: routeData.app.techblog.detail.data
        },
      },
      wavemakers: {
        path: 'campaign/wavemakers',
        data: routeData.app.wavemakers.data,
      },
      notFound: '*',
    },
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          settings: [
            groq`*[_id == 'settings'] | order(_updatedAt desc) [0] {
              shareImage,
              organisation
            }`,
            []
          ],
          menu: snippets.singletonDocument(
            'menu', groq`{
              'mainMenuItems': menuItems[] {
                label,
                _key,
                _type,
                'ref': reference->${snippets.linkableDocumentObject},
                _type == 'submenu' => {
                  submenuItems[] {
                    _key,
                    _type,
                    image,
                    'label': coalesce(referenceWithLabel.label, referenceWithLabel.ref->displayTitle, referenceWithLabel.ref->title),
                    'ref': referenceWithLabel.ref->${snippets.linkableDocumentObject},
                    'subtitle': referenceWithLabel.ref->subtitle
                  }
                },
                _type == 'referenceWithLabel' => ${snippets.referenceWithLabelAllowedTechblogOverview},
              },
              footerMenuItems[] {
                _type == 'referenceWithLabel' => ${snippets.referenceWithLabelObject},
                _type == 'urlWithLabel' => ${snippets.urlWithLabelObject}
              },
              preboardingMenuItems[] {
                _key,
                _type == 'referenceWithLabel' => ${snippets.referenceWithLabelObject}
              }
            }`,
            { language }
          ),
        }),
        fetch: {
          cookieConfiguration: requestHandlers => requestHandlers.getCookieConfiguration,
          userTracking: requestHandlers => requestHandlers.getUserTrackingData,
          skillsMatchUserSelectionCount: requestHandlers => requestHandlers.getSkillsMatchUserSelectionCount,
          status: requestHandlers => requestHandlers.getStatusCodeFromGeoLocation,
          jobsData: requestHandlers => requestHandlers.jobsByVakgebied,
          userCountry: requestHandlers => requestHandlers.getUserCountry,
        },
        derived: ({ data, params }) => ({
          showCookieConsent: data?.cookieConfiguration?.configuration.show && data?.doc?._type !== 'cookiePolicy',
          dataLayer: {
            ...cookieConfiguration(data?.cookieConfiguration?.configuration ?? null),
            user: {
              rb_uuid: data?.userTracking?.userId,
              rb_sid: data?.userTracking?.userSessionId,
              skillmatch_count: data?.skillsMatchUserSelectionCount
            },
            metadata: {
              content: {
                language: params.language,
                ...(data.doc?._id && { id: data.doc?._id }),
              }
            }
          }
        }),
        extractParams: { language: extract(language) }
      },
      home: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'home', groq`{
                _id,
                _key,
                _type,
                'title': coalesce(displayTitle, title),
                'imageTiles' : heroContentTiles.tiles[] ${snippets.tilesObject},
                "videoPortrait" : heroContentTiles.videoPortrait,
                "videoLandscape" : heroContentTiles.videoLandscape,
                'gridItems': content[] ${snippets.homeGridObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            latestJobs: requestHandlers => requestHandlers.latestJobs,
            totalJobsCount: requestHandlers => requestHandlers.totalJobsCount,
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            description: x => x.intro,
            type: 'home',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          }),
          extractParams: { home: extract(language) }
        }
      },

      article: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: snippets.document(
              'article', groq`{
                _id,
                _updatedAt,
                _createdAt,
                readingTime,
                employees[]->${snippets.employeeObject},
                featuredMedia[0],
                intro[] ${snippets.contentSimpleObject},
                'title': coalesce(displayTitle, title),
                introTitle,
                content[] ${snippets.contentObject},
                seo,
                geoBlocking,
                taxonomy ${snippets.taxonomyObject},
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language, slug }
            ),
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          extractParams: { article: extract(language, slug) },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'article-detail',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          })
        }
      },
      page: {
        data: {
          groq: ({ params: { slug, language } }) => ({
            doc: [
              groq`*[_type in $types && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                _id,
                _updatedAt,
                _createdAt,
                _type,
                'title': coalesce(displayTitle, title),
                _type == 'page' => {
                  intro[] ${snippets.contentSimpleObject},
                  content[] ${snippets.contentObject},
                  featuredMedia[0],
                  introTitle,
                },
                _type == 'panelPage' => {
                  intro,
                  featuredMedia,
                  panels[]->${snippets.panelObject},
                  'labels': content[].metadata,
                },
                seo,
                taxonomy ${snippets.taxonomyObject},
                geoBlocking,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language, slug, types: ['page', 'panelPage'] }
            ],
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          extractParams: {
            page: extract(language, slug),
            panelPage: extract(language, slug),
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'page',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => x._type === 'panelPage'
              ? x?.panels?.reduce((total, panel) => total + wordCount(panel.content ?? []), 0)
              : wordCount(x.content ?? []),
          })
        }
      },
      preboarding: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'preboardingPage', groq`{
                _id,
                _updatedAt,
                _createdAt,
                _type,
                intro[] ${snippets.contentSimpleObject},
                'title': coalesce(displayTitle, title),
                introTitle,
                content[] ${snippets.contentObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          extractParams: {
            preboardingPage: extract(language),
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'preboarding-overview',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          })
        },
        childPage: {
          data: {
            groq: ({ params: { slug, language } }) => ({
              doc: snippets.document(
                'preboardingChildPage', groq`{
                  _id,
                  _updatedAt,
                  _createdAt,
                  _type,
                  intro[] ${snippets.contentSimpleObject},
                  'title': coalesce(displayTitle, title),
                  introTitle,
                  content[] ${snippets.contentObject},
                  seo,
                  'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                }`,
                { language, slug }
              ),
            }),
            fetch: {
              enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
            },
            extractParams: {
              preboardingChildPage: extract(language, slug),
            },
            derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
              type: 'preboarding-detail',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x.content ?? []),
            })
          }
        }
      },
      traineeship: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'traineeshipsOverview', groq`{
                _id,
                _updatedAt,
                _type,
                _createdAt,
                intro,
                subtitle,
                programIntro,
                imageGrid,
                content[] ${snippets.contentObject},
                'traineeships': *[_type == 'traineeship' && language == $language] ${snippets.traineeshipDetailObject},
                'title': coalesce(displayTitle, title),
                language,
                seo,
                taxonomy ${snippets.taxonomyObject},
                geoBlocking,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          derived: ({ data, derived, route }) => {
            return deriveDocumentInformation({ data, derived }, {
              title: x => x.title,
              canonicalUrl: data.doc && route(extract(language)(data.doc)),
              type: 'traineeships-overview',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x.content ?? []),
            })
          },
          extractParams: { traineeshipsOverview: extract(language) }
        },
        detail: {
          data: {
            groq: ({ params: { slug, language } }) => ({
              doc: snippets.document(
                'traineeship', groq`{
                _id,
                _updatedAt,
                _createdAt,
                'title': coalesce(displayTitle, title),
                headerImage,
                intro[] ${snippets.contentSimpleObject},
                introTitle,
                contentIntro,
                properties ${snippets.traineeshipPropertiesObject},
                openDate,
                startDate,
                openCloseLabel,
                content[] ${snippets.contentObject},
                seo,
                taxonomy,
                language,
                geoBlocking,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
                { language, slug }
              ),
            }),
            fetch: {
              enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
            },
            extractParams: { traineeship: extract(language, slug) },
            derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
              type: 'traineeship-detail',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x.content ?? []),
            })
          }
        }
      },

      jobAlert: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'jobAlert', groq`{
                _id,
                _updatedAt,
                _createdAt,
                'title': coalesce(displayTitle, title),
                content[] ${snippets.contentObject},
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            filters: requestHandlers => requestHandlers.jobFilters,
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'jobAlert',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          }),
          extractParams: { jobAlert: extract(language) }
        },

        verify: {
          data: {
            fetch: {
              verifyResponse: requestHandlers => requestHandlers.jobAlertSubscriptionVerify,
            },
            derived: ({ data, derived, params }) => deriveJobAlertSubscriptionInformation({ data, derived, params }, {
              type: 'jobAlertSubscriptionVerify',
              title: params => translateSingular(params.language, 'job-alert-subscription-verify-page-title'),
            }),
          }
        },

        unsubscribe: {
          data: {
            fetch: {
              unsubscribeResponse: requestHandlers => requestHandlers.jobAlertSubscriptionUnsubscribe,
            },
            derived: ({ data, derived, params }) => deriveJobAlertSubscriptionInformation({ data, derived, params }, {
              type: 'jobAlertSubscriptionUnsubscribe',
              title: params => translateSingular(params.language, 'job-alert-subscription-unsubscribe-page-title'),
            }),
          }
        }
      },

      jobs: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'jobs', groq`{
                _id,
                _updatedAt,
                _createdAt,
                'title': coalesce(displayTitle, title),
                heroImages,
                language,
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            initialJobs: requestHandlers => requestHandlers.jobs,
            filters: requestHandlers => requestHandlers.jobFilters,
            totalJobsCount: requestHandlers => requestHandlers.totalJobsCount,
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          derived: ({ data, derived, route }) => deriveDocumentInformation({ data, derived }, {
            title: x => x.title,
            canonicalUrl: data.doc && route(extract(language)(data.doc)),
            type: 'job-overview',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
          }),
          extractParams: { jobs: extract(language) }
        },
      },

      job: {
        data: {
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.jobAndAdditionalData,
          },
          derived: ({ data, derived, params }) => data.enrichedPageData?.job && derivedJobInformation({ data, derived, params }),
        },
      },

      vakgebied: {
        data: {
          groq: ({ params: { vakgebiedTitle, language } }) => ({
            doc: snippets.document(
              'vakgebied', groq`{
                _id,
                _updatedAt,
                _createdAt,
                _type,
                language,
                slug,
                'title': coalesce(displayTitle, title),
                intro[] ${snippets.contentSimpleObject},
                subvakgebieden ${snippets.subvakgebiedenObject},
                ctaToJobs ${snippets.ctaToJobsObject},
                content[] ${snippets.contentObject},
                'tiles': tiles.tiles[]${snippets.tilesObject},
                imageGrid,
                video,
                seo,
                intractiveVideoIframe,
                taxonomy ${snippets.taxonomyObject},
                geoBlocking,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language, slug: vakgebiedTitle }
            ),
          }),
          extractParams: {
            vakgebied: extract(
              language,
              (document) => ({ vakgebiedTitle: document.slug?.current })
            )
          },
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData,
            jobsData: requestHandlers => requestHandlers.jobsByVakgebied,
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'expertise-detail',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          })
        },

        subvakgebied: {
          data: {
            groq: ({ params: { vakgebiedTitle, slug, language } }) => ({
              doc: snippets.subvakgebied(
                groq`{
                  _id,
                  _updatedAt,
                  _createdAt,
                  _type,
                  language,
                  'title': coalesce(displayTitle, title),
                  imageGrid,
                  intro[] ${snippets.contentSimpleObject},
                  video,
                  ctaToJobs ${snippets.ctaToJobsObject},
                  content[] ${snippets.contentObject},
                  seo,
                  enableFixedButton,
                  taxonomy ${snippets.taxonomyObject},
                  geoBlocking,
                  "vakgebiedSlug": vakgebied->slug,
                  vakgebied->{"title": coalesce(displayTitle, title), taxonomy, slug },
                  subvakgebiedATSId,
                  'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                }`,
                { language, parentSlug: vakgebiedTitle, slug }
              ),
            }),

            extractParams: {
              subvakgebied: extract(
                language,
                slug,
                (document, { client }) => {
                  if (document.vakgebiedSlug) return { vakgebiedTitle: document.vakgebiedSlug.current }
                  if (!client && !document.vakgebied) {
                    if (document._id.startsWith('drafts.')) return { vakgebiedTitle: 'draft' }
                    throw new Error('It seems that you did not connect the subvakgebied to a parent, this could be a translation issue.')
                  }

                  return client.getDocument(document.vakgebied._ref).then(x => ({ vakgebiedTitle: x.slug.current }))
                },
              )
            },
            derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
              type: 'subexpertise',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x.content ?? []),
            }),
            fetch: {
              enrichedPageData: requestHandlers => requestHandlers.enrichedPageData,
              jobsData: requestHandlers => requestHandlers.jobsByVakgebied,
            },
          }
        }
      },

      cookiePolicy: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'cookiePolicy', groq`{
                _id,
                _updatedAt,
                _createdAt,
                _type,
                'title': coalesce(displayTitle, title),
                content[] ${snippets.contentObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            )
          }),
          extractParams: { cookiePolicy: extract(language) },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'cookie-policy',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          })
        },
      },

      faq: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'faqPage', groq`{
                _id,
                _updatedAt,
                _createdAt,
                _type,
                heroImage,
                intro[] ${snippets.contentSimpleObject},
                'title': coalesce(displayTitle, title),
                introTitle,
                content[] ${snippets.contentObject},
                seo,
                taxonomy ${snippets.taxonomyObject},
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            )
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
          },
          extractParams: { faqPage: extract(language) },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'faq-page',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          })
        },
      },

      techblog: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'techblogOverview', groq`{
                _id,
                _updatedAt,
                _createdAt,
                'title': coalesce(displayTitle, title),
                'categories': categoriesOrder[]->,
                language,
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            initialTechblogs: requestHandlers => requestHandlers.techblogs,
            filters: requestHandlers => requestHandlers.techblogFilters,
            totalTechblogsCount: requestHandlers => requestHandlers.totalTechblogsCount,
          },
          derived: ({ data, derived, route }) => deriveDocumentInformation({ data, derived }, {
            title: x => x.title,
            canonicalUrl: data.doc && route(extract(language)(data.doc)),
            type: 'techblog-overview',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          }),
          extractParams: { techblogOverview: extract(language) }
        },
        detail: {
          data: {
            groq: ({ params: { slug, language } }) => ({
              doc: snippets.document(
                'techblog', groq`{
                  _id,
                  _updatedAt,
                  _createdAt,
                  'publishDate': coalesce(publishDate, _createdAt),
                  readingTime,
                  employees[]->${snippets.employeeObject},
                  'title': coalesce(displayTitle, title),
                  intro[] ${snippets.contentSimpleObject},
                  content[] ${snippets.contentObject},
                  'categories': techblogTaxonomy.techblogCategories[]->,
                  'tags': techblogTaxonomy.technicalSkills[]->,
                  taxonomy ${snippets.taxonomyObject},
                  seo,
                  'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                }`,
                { language, slug }
              ),
            }),
            fetch: {
              enrichedPageData: requestHandlers => requestHandlers.enrichedPageData
            },
            extractParams: { techblog: extract(language, slug) },
            derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
              type: 'techblog-detail',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x.content ?? []),
            })
          }
        }
      },

      topics: {
        data: {
          groq: ({ params: { language } }) => ({
            doc: snippets.singletonDocument(
              'topicOverview', groq`{
                _id,
                _updatedAt,
                _createdAt,
                'title': coalesce(displayTitle, title),
                intro[] ${snippets.contentSimpleObject},
                seo,
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            filters: requestHandlers => requestHandlers.topicsFilters,
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            type: 'topics-overview',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
          }),
          extractParams: { topicOverview: extract(language) }
        },

        detail: {
          data: {
            groq: ({ params: { slug, language } }) => ({
              doc: [
                groq`*[_type in $types && language == $language && slug.current == $slug] | order(_updatedAt desc) [0] {
                  ${snippets.topicDetailObject},
                  'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
                }`,
                { language, slug, types: ['topicPodcast', 'topicArticle', 'topicVideo', 'topicFact'] }
              ],
            }),
            extractParams: {
              topicPodcast: extract(language, slug),
              topicArticle: extract(language, slug),
              topicVideo: extract(language, slug),
              topicFact: extract(language, slug)
            },
            derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
              type: 'topics-detail',
              dateCreated: x => date(x._createdAt),
              dateUpdated: x => date(x._updatedAt),
              wordCount: x => wordCount(x?.content ?? []),
            })
          }
        },
      },

      wavemakers: {
        data: {
          groq: ({ params: { language  } }) => ({
            doc: snippets.singletonDocument(
              'wavemakers', groq`{
                _id,
                _key,
                _type,
                'title': coalesce(displayTitle, title),
                hero {
                  heading,
                  subheading,
                  heroVideoDesktop,
                  heroVideoMobile,
                },
                content[] ${snippets.contentObject},
                seo,
                taxonomy ${snippets.taxonomyObject},
                'translations': ${snippets.translations(snippets.linkableDocumentObjectWithTitle)},
              }`,
              { language }
            ),
          }),
          fetch: {
            enrichedPageData: requestHandlers => requestHandlers.enrichedPageData,
            jobsData: requestHandlers => requestHandlers.jobsByVakgebied,
          },
          derived: ({ data, derived }) => deriveDocumentInformation({ data, derived }, {
            description: x => x.intro,
            type: 'wavemakers',
            dateCreated: x => date(x._createdAt),
            dateUpdated: x => date(x._updatedAt),
            wordCount: x => wordCount(x.content ?? []),
          }),
          extractParams: { wavemakers: extract(language) }
        }
      },
    }
  }
}

function deriveDocumentInformation(
  {
    data,
    derived = { dataLayer: {} }
  },
  {
    id = x => x._id,
    title = x => x.displayTitle ?? x.title,
    description = _ => undefined,
    shareImage = _ => data.settings?.shareImage,
    dateUpdated = _ => undefined,
    dateCreated = _ => undefined,
    wordCount = _ => undefined,
    type = undefined,
    canonicalUrl = undefined,
  } = {}
) {
  const { doc, enrichedPageData } = data
  const { enrichedContentBlock } = enrichedPageData || {}
  if (!doc) return

  const { vakgebied, proofPoints } = transformTaxonomyDataForDocument(doc)
  const dateUpdatedValue = dateUpdated(doc)
  const dateCreatedValue = dateCreated(doc)
  const wordCountValue = wordCount(doc)

  const contentFaqItems = [].concat(
    ...(doc.content?.filter(x => x?._type === 'faqs') || [])
      .map(x => (x?.questions || [])
        .map(x => ({ question: x.question, answer: toPlainText(x.blocks) }))
      )
  )

  const normalizedDoc = enrichedContentBlock ? { ...doc, content: enrichedContentBlock } : doc

  return {
    doc: docWithSeo(normalizedDoc, {
      title: title(doc),
      description: description(doc),
      social: {
        shareImage: shareImage(doc)
      },
      ...(canonicalUrl && { advanced: { canonicalUrl } }),
    }),
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        content: {
          ...derived.dataLayer?.metadata?.content,
          id: id(doc),
          ...(doc?.taxonomy?.vakgebied && { expertise: doc.taxonomy.vakgebied }),
          title: title(doc),
          ...(dateUpdatedValue && { dateupdated: dateUpdatedValue }),
          ...(dateCreatedValue && { datecreated: dateCreatedValue }),
          ...(wordCountValue && { wordcount: wordCountValue }),
          type,
          expertise: vakgebied,
          proofpoints: proofPoints.length ? proofPoints?.join('||') : undefined,
        }
      }
    },
    structuredData: (contentFaqItems.length > 0) && ({
      type: 'FAQPage',
      data: {
        entries: contentFaqItems
      }
    }),
  }
}

function deriveJobAlertSubscriptionInformation({ data, derived, params }, { title, type }) {
  const documentTitle = title(params)

  return {
    doc: {
      title: documentTitle,
      description: '',
      social: {
        shareImage: data.settings?.shareImage,
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        content: {
          ...derived.dataLayer?.metadata?.content,
          title: documentTitle,
          type,
        }
      }
    },
  }
}

function derivedJobInformation({ data, derived, params }) {
  const job = data.enrichedPageData.job ?? {}
  const canonicalUrl = data.enrichedPageData.canonicalUrl

  const dateCreatedValue = date(job.date_start)
  const dateUpdatedValue = date(job.date_updated)
  const wordCountValue = wordCount(job.job_description ?? [])

  return {
    doc: {
      ...docWithSeo(data.enrichedPageData, {
        title: job.job_title,
        description: job?.city ?
          translateSingular(params.language, 'job-meta-description-with-location', { jobTitle: job.job_title, jobCity: job.city }) :
          translateSingular(params.language, 'job-meta-description-without-location', { jobTitle: job.job_title }),
        social: {
          shareImage: data.enrichedPageData?.additionalData?.sanityData?.vakgebied?.seo?.social?.shareImage || data.settings?.shareImage
        },
        ...(canonicalUrl && { advanced: { canonicalUrl } }),
      }),
      _type: 'job'
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        content: {
          ...derived.dataLayer.metadata.content,
          id: job.job_id,
          title: job.job_title,
          type: 'job-detail',
          ...(dateUpdatedValue && { dateupdated: dateUpdatedValue }),
          ...(dateCreatedValue && { datecreated: dateCreatedValue }),
          ...(wordCountValue && { wordcount: wordCountValue }),
        },
        job: mapJobForDataLayer(job),
        workday_source: data?.enrichedPageData?.userOriginData?.utmSource || null,
        workday_medium: data?.enrichedPageData?.userOriginData?.utmMedium || null,
        workday_referer: data?.enrichedPageData?.userOriginData?.referer || null,
      }
    },
    structuredData: {
      type: 'JobPosting',
      data: {
        publishedDate: job.date_start,
        employmentType: (job.contract_type && job.contract_type[0]?.code) || '',
        description: toPlainText(job.job_description || []),
        city: job.city,
        address: job.address,
        postcode: job.postcode,
        country: (job.country && job.country[0]?.code) || language,
        title: job.job_title,
        id: job.job_id,
      }
    },
  }
}
