export function useFocusWithin({ isEnabled, onBlurChange, onFocusChange }) {
  const focusRef = React.useRef(false)
  const handleBlur = React.useCallback(
    (e) => {
      const isOutsideFocus = focusRef.current && !e.currentTarget.contains(e.relatedTarget)

      if (isOutsideFocus) {
        onBlurChange(e)
      }

      focusRef.current = false
    },
    [onBlurChange]
  )
  const handleFocus = React.useCallback(
    (e) => {
      if (!isEnabled && document.activeElement === e.target) {
        onFocusChange(e)
      }

      focusRef.current = true
    },
    [isEnabled, onFocusChange]
  )
  return { onBlur: handleBlur, onFocus: handleFocus }
}
