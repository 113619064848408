import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Tile } from '/features/buildingBlocks/tile/Tile'
import { ContentPanel } from '/features/buildingBlocks/Content'

import styles from './Panel.css'

export function Panel({ title, content, tiles, contentPlaceAlignment, backgroundImage = undefined, availableStyleContexts = undefined }) {
  return (
    <div className={styles.component}>
      <div className={cx(styles.inner, contentPlaceAlignment === 'left' && styles.reversed)}>
        <div className={styles.content}>
          {title && <HeadingMd layoutClassName={styles.titleLayout} h={3} {... { title }} />}
          {content && <ContentPanel {... { content }} />}
        </div>

        <div className={cx(styles.tiles, contentPlaceAlignment === 'left' && styles.reversed)}>
          {tiles?.filter(x => x.representation)?.map((x, i) => (
            <Tile
              key={i}
              tile={x}
              {...{ availableStyleContexts }}
            />
          ))}
        </div>
      </div>

      {backgroundImage?.asset && <div className={styles.backgroundImage}>
        <ImageCover aspectRatio={16 / 9} image={backgroundImage} />
      </div>}
    </div>
  )
}
