import { Icon } from '/features/buildingBlocks/Icon'
import { useSpring, animated } from '@react-spring/web'

import playIcon from '/images/icons/play.raw.svg'
import pauseIcon from '/images/icons/pause.raw.svg'

import styles from './PlayButtonAndTitle.css'

export function PlayButtonSmall({ onClick, layoutClassName, playing }) {
  return (
    <PlayButtonAndTitleBase
      className={cx(styles.componentPlayButtonSmall, layoutClassName)}
      buttonContainerStyle={styles.smallButtonContainer}
      {...{ onClick, playing }}
    />
  )
}

export function PlayButtonAndTitleSmall({ onClick, layoutClassName, title, playing }) {
  return (
    <PlayButtonAndTitleBase
      className={cx(styles.componentSmall, layoutClassName)}
      buttonContainerStyle={styles.smallButtonContainer}
      {...{ onClick, title, playing }}
    />
  )
}

export function PlayButtonAndTitleLarge({ onClick, layoutClassName, title, playing }) {
  return (
    <PlayButtonAndTitleBase
      className={cx(styles.componentLarge, layoutClassName)}
      buttonContainerStyle={styles.largeButtonContainer}
      {...{ onClick, title, playing }}
    />
  )
}

export function PlayButtonAndTitleWavemakers({ onClick, layoutClassName, title, playing }) {
  return (
    <PlayButtonAndTitleBase
      className={cx(styles.componentWavemakers, layoutClassName)}
      buttonContainerStyle={styles.wavemakersButtonContainer}
      {...{ onClick, title, playing }}
    />
  )
}

function PlayButtonAndTitleBase({ onClick, className, playing, buttonContainerStyle, title = undefined }) {
  const spring = useSpring({
    opacity: playing ? 0 : 1
  })

  return (
    <div className={cx(styles.componentBase, className)}>
      <animated.div
        style={spring}
        className={cx(styles.buttonContainer, buttonContainerStyle)}
      >
        <button
          data-x={playing ? 'pause-video' : 'play-video'}
          aria-label={playing ? 'pause' : 'play'}
          className={styles.button}
          {... { onClick }}
        >
          <Icon icon={playing ? pauseIcon : playIcon} />
        </button>
        {title && <h3 className={styles.headingButton}>{title}</h3>}
      </animated.div>
    </div>
  )
}
