export const salaryData = [
  {
    'scale': 1,
    'salaryMin': 1351.74,
    'salaryMax': 1929.89,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 2,
    'salaryMin': 1572.76,
    'salaryMax': 2245.24,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 3,
    'salaryMin': 1758.76,
    'salaryMax': 2510.76,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 4,
    'salaryMin': 1968.98,
    'salaryMax': 2812.66,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 5,
    'salaryMin': 2344.98,
    'salaryMax': 3349.03,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 6,
    'salaryMin': 2716.94,
    'salaryMax': 3880.02,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 7,
    'salaryMin': 3179.19,
    'salaryMax': 4540.36,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 8,
    'salaryMin': 3711.55,
    'salaryMax': 5300.48,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 9,
    'salaryMin': 4331.42,
    'salaryMax': 6186.37,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 10,
    'salaryMin': 5161.82,
    'salaryMax': 7374.01,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 11,
    'salaryMin': 6211.25,
    'salaryMax': 8873.20,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 36
  },
  {
    'scale': 12,
    'salaryMin': 8491.46,
    'salaryMax': 12130.65,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 40,
    'label': 'SKA'
  },
  {
    'scale': 13,
    'salaryMin': 10412.04,
    'salaryMax': 14874.37,
    'ebbMultiplier': 0.1,
    'holiday': 0.08,
    'extraMonth': 0.08333333333,
    'hours': 40,
    'label': 'SKB'

  }
]
