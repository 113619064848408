import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { LinkToTechblogsOverview } from '/features/pageOnly/techblog/LinkToTechblogsOverview'

import styles from './TechblogTags.css'

export function TechblogTags({ tags }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      <div className={styles.heading}>
        <HeadingMd h={2} title={__`article-tags-header`} />
      </div>

      <div className={styles.linksContainer}>
        <div className={styles.tagsContainer}>
          {tags?.map(({ title, value, key: keyValue }, i) => (
            <Tag key={i} {...{ title, value, keyValue }} />
          ))}
        </div>

        <LinkToTechblogsOverview />
      </div>

    </div>
  )
}

function Tag({ title, value, keyValue }) {
  const language = useLanguage()

  return (
    <a
      className={styles.componentTag}
      href={`${routeMap.app.techblog.overview({ language })}?${keyValue}[]=${value}`}
      data-x='link-to-filtered-articles'
    >
      {title}
    </a>
  )
}
