import { HeadingSm } from '/features/buildingBlocks/Heading'
import { RewardCalculatorPortalFlow } from '/features/buildingBlocks/reward-calculator/RewardCalculatorPortalFlow'
import { Icon } from '/features/buildingBlocks/Icon'
import { routeMap } from '/routeMap'
import { useTranslate, useLanguage } from '/machinery/I18n'

import arrowIcon from '/images/icons/arrow.raw.svg'

import styles from './RewardCalculatorCtaJobsOverview.css'

export function RewardCalculatorCtaJobsOverview({ title, text, benefitPageRef }) {
  const [showRewardCalculatorPortal, setShowRewardCalculatorPortal] = React.useState(false)
  const { __ } = useTranslate()
  const language  = useLanguage()

  const link = benefitPageRef && {
    url: routeMap.app.page({ language, slug: benefitPageRef.slug.current }),
    label: __`see-the-benefits`
  }

  return (
    <>
      <div data-style-context='blue' className={styles.cta}>
        <div className={styles.content}>
          <HeadingSm layoutClassName={styles.headingLayout} h={3} {...{ title }} />
          <p className={styles.text}>{text}</p>
        </div>

        <div className={styles.icon}><Icon icon={arrowIcon} /></div>

        <button
          type='button'
          className={styles.button}
          data-x='open-calculator-jobs-overview'
          onClick={() => setShowRewardCalculatorPortal(true)}
          aria-label='open calculator'
        />
      </div>

      <RewardCalculatorPortalFlow
        onChange={setShowRewardCalculatorPortal}
        isOpen={showRewardCalculatorPortal}
        {...{ link }}
      />
    </>
  )
}
