import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { dayjs } from '/machinery/dayjs'

import { Card, CardWithPermanentArrowIcon } from '/features/buildingBlocks/card/Card'
import { navigateWithParams } from '/machinery/navigateWithParams'
import { Labels } from '/features/buildingBlocks/Labels'

import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './TechblogResults.css'

export function TechblogResults({ techblogs, isFetching }) {
  const language = useLanguage()

  return (
    <ul className={cx(styles.component, isFetching && styles.isLoading)}>
      {techblogs?.map(({ _source: techblog }, i) => {
        const {
          id,
          slug,
          category: categories,
          title,
          employees,
          readingTime,
          publishDate
        } = techblog
        const date = publishDate * 1000

        const isBelowFiveResults = techblogs?.length < 5
        const isFourthItem = i % 10 === 4

        return (
          <React.Fragment key={`${id}__${i}`}>
            <li>
              <Card
                dataXContext={id}
                dataX='link-to-techblog'
                href={routeMap.app.techblog.detail({ language, slug: slug.current })}
              >
                <TechblogCard {...{ categories, title, employees, readingTime, date }} />
              </Card>
            </li>

            {(isFourthItem || isBelowFiveResults) && (
              <li>
                <CardWithPermanentArrowIcon
                  href={navigateWithParams(routeMap.app.jobs({ language }), { job_branch: ['it'] })}
                  dataX='link-to-it-vacancies'
                  dataStyleContext='blue'
                >
                  <JobAlertCard />
                </CardWithPermanentArrowIcon>
              </li>
            )}
          </React.Fragment>
        )
      })}
    </ul>
  )
}

function TechblogCard({ categories: categoriesRaw, title, employees, readingTime, date }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const categories = categoriesRaw?.length > 0
    ? categoriesRaw.map(x => x?.[`label_${language}`] ?? x?.label_en ?? null).filter(Boolean)
    : []

  const labels = [
    ...(date) ? [{ title: dayjs(date).locale(language).format('LL') }] : [],
    ...(readingTime) ? [{ title: __(Number(readingTime), { count: readingTime })`article-reading-time` }] : [],
  ]

  return (
    <div className={styles.componentTechblogCard}>
      {Boolean(categories.length) && <Categories layoutClassName={styles.categoriesLayout} {...{ categories }} />}
      <h4 className={cx(styles.title, styles.blueTitle)}>{title}</h4>
      <div className={styles.metadata}>
        <Authors layoutClassName={styles.authorsLayout} {...{ employees }} />
        <span className={styles.seperator} />
        <Labels {...{ labels }} />
      </div>
    </div>
  )
}

function JobAlertCard() {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentJobAlertCard}>
      <h4 className={styles.title}>Discover our IT vacancies</h4>
      <p>View all open positions and apply</p>
    </div>
  )
}

function Categories({ layoutClassName, categories }) {
  return (
    <ul className={cx(styles.componentCategories, layoutClassName)}>
      {categories.map((category, i) => (
        <li key={i} className={styles.category}>
          <span>{category}</span>
        </li>
      ))}
    </ul>
  )
}

function Authors({ employees, layoutClassName }) {
  const employeesWithAvatar = employees?.filter(x => x.avatar) || []
  const employeeTitles = employees?.filter(x => x.title) || []

  return (
    <div className={cx(styles.componentAuthors, layoutClassName)}>
      {Boolean(employeesWithAvatar.length) && (
        <div className={styles.avatars}>
          {employeesWithAvatar.map((x, i) => (
            <div key={`image__${x._key}__${i}`} className={styles.avatar}>
              <ImageCover aspectRatio={1 / 1} image={x.avatar} />
            </div>
          ))}
        </div>
      )}
      {Boolean(employeeTitles.length) && (
        <div className={styles.titles}>
          {employeeTitles.map((x, i) => (
            <React.Fragment key={`employee__${x._key}__${i}`}>{i > 0 && ' & '}<span>{x.title}</span></React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
