import { determineLinkProps } from '/machinery/link'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

import chevronIcon from '/images/icons/chevron.raw.svg'

export function ButtonPrimary({ dataX, label, tabIndex = 0, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentPrimary, layoutClassName)} {...{ dataX, label, tabIndex, onClick, type, disabled }} />
}

export function ButtonSecondary({ dataX, label, tabIndex = 0, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentSecondary, layoutClassName)} {...{ dataX, label, tabIndex, onClick, type, disabled }} />
}

export function ButtonTertiary({ dataX, label, tabIndex = 0, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentTertiary, layoutClassName)} {...{ dataX, label, tabIndex, onClick, type, disabled }} />
}

export function ButtonWithIcon({ label, dataX, tabIndex = 0, onClick = undefined, disabled = false, type = 'button', layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentWithIcon, layoutClassName)} icon={chevronIcon} {...{ dataX, tabIndex, onClick, type, label, disabled }} />
}

export function ButtonLinkPrimary({ href, dataX, tabIndex = 0, label, targetSelf = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkPrimary, layoutClassName)} {...{ href, dataX, tabIndex, label, targetSelf, layoutClassName }} />
}

export function ButtonLinkTertiary({ href, dataX, tabIndex = 0, label, targetSelf = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkTertiary, layoutClassName)} {...{ href, dataX, tabIndex, label, targetSelf, layoutClassName }} />
}

function ButtonBase({ className, dataX, label, onClick, type, disabled, icon = undefined, tabIndex = 0 }) {
  return (
    <button
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentBase, className)}
      {...{ onClick, type, disabled, tabIndex }}
    >
      {icon && (
        <span className={styles.icon}>
          <Icon {...{ icon }} />
        </span>
      )}
      {label}
    </button>
  )
}

function ButtonLinkBase({ href, dataX, label, tabIndex = 0, targetSelf = undefined, icon = undefined, className, layoutClassName = undefined }) {
  return <LinkBase className={cx(styles.componentLinkBase, className, layoutClassName)} {...{ href, dataX, tabIndex, label, icon, targetSelf }} />
}

export function ButtonLinkWithIcon({ label, href, dataX, tabIndex = 0, targetSelf = undefined, layoutClassName = undefined }) {
  return <ButtonLinkIconBase className={cx(styles.componentLinkWithIcon, layoutClassName)} icon={chevronIcon} {...{ href, dataX, tabIndex, targetSelf, label }} />
}

function ButtonLinkIconBase({ href, dataX, label, tabIndex = 0, targetSelf = undefined, icon = undefined, className, layoutClassName = undefined }) {
  return <LinkBase className={cx(styles.componentLinkIconBase, className, layoutClassName)} {...{ href, dataX, tabIndex, label, icon, targetSelf }} />
}

function LinkBase({ className, href, dataX, label, tabIndex = 0, targetSelf = undefined, icon = undefined }) {
  const { rel, target } = determineLinkProps({ href, targetSelf })

  return (
    <a
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentLinkBase, className)}
      {...{ href, rel, target, tabIndex }}
    >
      {icon && (
        <span className={styles.icon}>
          <Icon {...{ icon }} />
        </span>
      )}
      {label}
    </a>
  )
}
