export function useTopOfPage({ onChange }) {
  React.useEffect(
    () => {
      handleScroll()
      window.addEventListener('scroll', handleScroll)
      return () => window.addEventListener('scroll', handleScroll)

      function handleScroll() {
        onChange(window.scrollY === 0)
      }
    },
    [onChange]
  )
}
