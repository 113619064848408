import { Tile } from '/features/buildingBlocks/tile/Tile'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTopOfPage } from '/machinery/useTopOfPage'
import { useTranslate } from '/machinery/I18n'
import { ShowRewardCalculatorPortalFlowButton } from '/features/buildingBlocks/reward-calculator/ShowRewardCalculatorPortalFlowButton'

import mediaStyles from '/cssGlobal/media.css'
import styles from './TileGrid.css'

export function TileGrid({
  tiles,
  isDutchVacancy,
  linkRef = undefined,
  layoutClassName = undefined,
  availableStyleContexts = undefined
}) {
  const [topOfPage, setIsTopOfPage] = React.useState(true)
  useTopOfPage({
    onChange: (x) => setIsTopOfPage(x)
  })


  const viewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const compactGrid = viewportMd ? topOfPage : false

  const { __ } = useTranslate()

  return (
    <ul className={cx(styles.component, layoutClassName)}>
      {tiles?.filter(x => x.representation).map((x, i) => (
        <li
          key={[i, x._id].join()}
          data-context-tile-index={i}
          className={cx(styles.gridItem, compactGrid && styles.compact)}
        >
          <Tile
            blankTile={topOfPage && viewportMd}
            tile={x}
            {...{ availableStyleContexts }}
          />
        </li>
      ))}

      {isDutchVacancy && (
        <li
          data-context-tile-index={3}
          className={cx(styles.gridItem, compactGrid && styles.compact)}
        >
          <ShowRewardCalculatorPortalFlowButton
            {...{ linkRef }}
            layoutClassName={styles.calculatorLayout}
            label={__`salary-checker`}
            buttonType='tile'
            dataX='link-to-salary-detail'
          />
        </li>
      )}
    </ul>
  )
}
