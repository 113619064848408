import { useSpring, animated } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ImageCover } from '/features/buildingBlocks/Image'
import { VideoLarge } from '/features/buildingBlocks/Video'
import { IntractiveVideoIframe } from '/features/pageOnly/IntractiveVideoIframe'
import { PlayButtonAndTitleLarge } from '/features/buildingBlocks/PlayButtonAndTitle'
import { useFloating, FloatingPortal, useDismiss, FloatingFocusManager, useInteractions, FloatingOverlay, useRole, useFocus } from '@floating-ui/react'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import closeIcon from '/images/icons/close.raw.svg'

import mediaStyles from '/cssGlobal/media.css'
import styles from './VideoHeroGrid.css'

export function VideoHeroGrid({ video, videoHeroGridImages, intractiveVideoIframe, layoutClassName }) {
  const isMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const leftGridImageSpring = useSpring({
    from: { x: '-50%', y: isMd ? '50%' : '-10%' },
    to: { x: '-70%', y: isMd ? '30%' : '-30%' },
  })

  const rightGridImageSpring = useSpring({
    from: { x: '50%', y: '50%' },
    to: { x: '70%', y: '30%' },
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {videoHeroGridImages?.length > 0 && (
        <animated.div style={leftGridImageSpring} className={styles.imageLeft}>
          <ImageCover layoutClassName={styles.imageLeftLayout} image={videoHeroGridImages[0]} aspectRatio={3 / 4} />
        </animated.div>
      )}

      {video?.src && !intractiveVideoIframe?.src && <Video {... { video }} />}

      {intractiveVideoIframe?.src && (
        <InteractiveVideoModal
          title={intractiveVideoIframe.title}
          src={intractiveVideoIframe.src}
          poster={intractiveVideoIframe.poster}
        />
      )}

      {videoHeroGridImages?.length > 1 && (
        <animated.div style={rightGridImageSpring}  className={styles.imageRight}>
          <ImageCover layoutClassName={styles.imageRightLayout} image={videoHeroGridImages[1]} aspectRatio={3 / 4} />
        </animated.div>
      )}
    </div>
  )
}

function Video({ video }) {
  return (
    <div className={styles.componentVideo}>
      <VideoLarge
        url={video.src}
        title={video.title}
        poster={video.poster}
        layoutClassName={styles.videoLayout}
      />
    </div>
  )
}

function InteractiveVideoModal({ title, src, poster }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  return (
    <>
      <div className={styles.content}>
        {poster && <div className={styles.poster}>
          <ImageCover aspectRatio={8 / 5} image={poster} />
        </div>}
        <PlayButtonAndTitleLarge
          onClick={() => setIsModalOpen(true)}
          layoutClassName={styles.playLayout}
          {...{ title }}
        />
      </div>
      <VideoPortal {...{ src }} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  )
}

function VideoPortal({ isOpen, onClose, src }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  })

  const role = useRole(context)
  const dismiss = useDismiss(context)
  const focus = useFocus(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([ dismiss, role, focus ])

  return isOpen && (
    <FloatingPortal>
      <FloatingFocusManager {...{ context }} modal>
        <FloatingOverlay
          // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.componentVideoPortal}
          lockScroll={isOpen}
        >
          <div
            ref={refs.setFloating}
            aria-hidden={!isOpen}
            className={styles.videoPortalWrapper}
            {...getFloatingProps()}
          >
            <IntractiveVideoIframe layoutClassName={styles.iframeLayout} iFrameLink={src} />
            <CloseButton {...getReferenceProps()} layoutClassName={styles.closeLayout} {...{ onClose }} />
          </div>
        </FloatingOverlay>
      </FloatingFocusManager>
    </FloatingPortal>
  )
}

function CloseButton({ onClose, layoutClassName }) {
  const { __ } = useTranslate()
  return (
    <button
      type='button'
      onClick={onClose}
      data-x='close-overlay'
      aria-label={__`close-overlay`}
      className={cx(styles.componentCloseButton, layoutClassName)}
    >
      <Icon icon={closeIcon} />
    </button>
  )
}
