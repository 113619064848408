import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import styles from './SocialShare.css'
import linkedinIcon from '/images/icons/linkedIn.raw.svg'
import whatsappIcon from '/images/icons/whatsapp.raw.svg'
import mailIcon from '/images/icons/mail.raw.svg'
import githubIcon from '/images/icons/github.raw.svg'
import linkIcon from '/images/icons/link.raw.svg'

export function SocialSharePage({ url, title, layoutClassName = undefined }) {
  return <SocialSharePageBase {...{ url, title, layoutClassName }} />
}

export function SocialSharePageTechblog({ url, title, layoutClassName = undefined }) {
  return (
    <SocialSharePageBase
      visibleItemsOverrides={{ whatsapp: false, mail: false }}
      {...{ url, title, layoutClassName }}
    />
  )
}

function ShareLink({ url, dataX, label, icon, layoutClassName }) {
  return (
    <a
      href={url}
      target='_blank'
      data-x={dataX}
      rel="noreferrer noopener"
      className={cx(styles.componentShareLink, layoutClassName)}
      aria-label={label}
    >
      <Icon {...{ icon }} />
    </a>
  )
}

function ShareLinks({ shareItems, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentShareLinks, layoutClassName)}>
      {shareItems.map(({ url, dataX, label, icon }, i) => (
        <ShareLink
          key={i}
          layoutClassName={styles.shareLinkLayout}
          {...{ url, dataX, label, icon }}
        />
      ))}
    </div>
  )
}

const socials = {
  linkedin: true,
  whatsapp: true,
  mail: true
}

function SocialSharePageBase({
  url,
  title,
  visibleItemsOverrides = /** @type {Partial<socials>} */({}),
  layoutClassName = undefined
}) {
  const { __ } = useTranslate()

  const encodedUrl = encodeURIComponent(url)
  const encodedTitle = encodeURIComponent(title)
  const visibleItemArray = getVisibleItems(visibleItemsOverrides)

  const shareItems =  [
    {
      _type: 'linkedin',
      dataX: 'share-via-linkedin',
      label: __`share-via-linkedin`,
      url: `https://www.linkedin.com/shareArticle?mini=true&amp;url=${encodedUrl}&amp;title=${encodedTitle}&amp;summary=`,
      icon: linkedinIcon
    },
    {
      _type: 'whatsapp',
      dataX: 'share-via-whatsapp',
      label: __`share-via-whatsapp`,
      url: `https://api.whatsapp.com/send?text=${encodedTitle} - ${encodedUrl}`,
      icon: whatsappIcon
    },
    {
      _type: 'mail',
      dataX: 'share-via-email',
      label: __`share-via-email`,
      url: `mailto:?subject=${encodedTitle}&body=${encodedUrl}`,
      icon: mailIcon
    }
  ].filter(x => visibleItemArray.includes(x._type))

  return <ShareLinks {...{ shareItems, layoutClassName }} />
}

function getVisibleItems(overrides) {
  return Object
    .entries({ ...socials, ...overrides })
    .filter(([_, v]) => Boolean(v))
    .map(([k]) => k)
}

const SocialShareAuthorIconsMap = {
  'linkedin': linkedinIcon,
  'github': githubIcon,
}

export function SocialShareAuthor({ links }) {
  const shareItems = links.map(([key, value]) => {
    return {
      dataX: `author-share-${key}`,
      label: key,
      url: value,
      icon: SocialShareAuthorIconsMap[key] || linkIcon
    }
  })

  return <ShareLinks {...{ shareItems }} />
}
