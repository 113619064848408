export function useJobFiltersWithValues({ filters, values }) {
  return React.useMemo(
    () => {
      return {
        job_branch: optionsWithSubOptionsWithLabelAndValue('job_branch', 'sub_expertise', filters, values),
        sub_expertise: optionsWithLabelAndValue('sub_expertise', filters, values),
        remote_type: optionsWithLabelAndValue('remote_type', filters, values),
        country: optionsWithLabelAndValue('country', filters, values),
        contract_type: optionsWithLabelAndValue('contract_type', filters, values),
      }
    }, [filters, values]
  )
}

export function useTechblogFiltersWithValues({ filters, values }) {
  return {
    tag: optionsWithLabelAndValue('tag', filters, values),
  }
}

export function useTopicFiltersWithValues({ filters, values }) {
  return {
    tag: optionsWithLabelAndValue('tag', filters, values),
  }
}

function optionsWithLabelAndValue(filterName, filters, values) {
  const filterData = filters.find(x => x.id === filterName)
  const value = values[filterName]

  return { ...filterData, value }
}

function optionsWithSubOptionsWithLabelAndValue(filterName, subFilterName, filters, values) {
  const filterData = filters.find(x => x.id === filterName)

  return {
    ...filterData,
    value: values[filterName],
    options: filterData.options.map(x => ({
      ...x,
      ...(x.subFilter && {
        subFilter: {
          ...x.subFilter,
          value: values[subFilterName],
        }
      })
    }))
  }

}
