import ReactPlayer from 'react-player/lazy'
import { getVideoMetaDataProps, trackVideoMetadata, useProgressTracker } from '/analytics/video'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { ImageCover } from '/features/buildingBlocks/Image'
import { PlayButtonAndTitleLarge, PlayButtonAndTitleSmall, PlayButtonAndTitleWavemakers } from '/features/buildingBlocks/PlayButtonAndTitle'
import { HeadingXl } from '/features/buildingBlocks/Heading'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Video.css'

const playerConfig = {
  vimeo: {
    playerOptions: {
      title: false
    },
  },
}

export function VideoSmall({ url, title, poster = undefined, layoutClassName = undefined }) {
  return (
    <VideoBase
      PlayerContainer={DefaultPlayerContainer}
      PlayButtonAndTitle={PlayButtonAndTitleSmall}
      className={cx(styles.componentSmall, layoutClassName)}
      {...{ url, title, poster }}
    />
  )
}

export function VideoPortret({ url, title, poster = undefined, layoutClassName = undefined }) {
  return (
    <VideoBase
      PlayerContainer={DefaultPlayerContainer}
      PlayButtonAndTitle={PlayButtonAndTitleSmall}
      className={cx(styles.componentPortret, layoutClassName)}
      aspectRatio={9 / 16}
      {...{ url, title, poster }}
    />
  )
}

export function VideoSquare({ url, title, poster = undefined, layoutClassName = undefined }) {
  return (
    <VideoBase
      PlayerContainer={DefaultPlayerContainer}
      PlayButtonAndTitle={PlayButtonAndTitleSmall}
      className={cx(styles.componentSquare, layoutClassName)}
      aspectRatio={4 / 3}
      {...{ url, title, poster }}
    />
  )
}

export function VideoSmallWithoutOverlay({ url, title, poster = undefined, layoutClassName = undefined }) {
  return (
    <VideoBase
      PlayerContainer={DefaultPlayerContainer}
      overlay={false}
      PlayButtonAndTitle={PlayButtonAndTitleSmall}
      className={cx(styles.componentSmallWithoutOverlay, layoutClassName)}
      {...{ url, title, poster }}
    />
  )
}

export function VideoLarge({ url, title, poster = undefined, layoutClassName = undefined }) {
  return (
    <VideoBase
      PlayerContainer={DefaultPlayerContainer}
      PlayButtonAndTitle={PlayButtonAndTitleLarge}
      className={cx(styles.componentLarge, layoutClassName)}
      {...{ url, title, poster }}
    />
  )
}

export function VideoWavemakers({
  url,
  title,
  playButtonHint,
  preview = undefined,
  mobilePoster = undefined,
  desktopPoster = undefined,
  layoutClassName = undefined
}) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const aspectRatio = isViewportMd ? 16 / 9 : 9 / 16
  const poster = isViewportMd ? desktopPoster : mobilePoster

  return (
    <VideoBase
      title={playButtonHint}
      PlayerContainer={WavemakersPlayerContainer}
      PlayButtonAndTitle={PlayButtonAndTitleWavemakers}
      className={cx(styles.componentWavemakers, layoutClassName)}
      renderAdditionalContent={({ playing, className }) => (
        <div
          data-style-context='dark'
          className={cx(className, styles.additionalContentWavemakers, playing && styles.isPlaying)}
        >
          <HeadingXl h={4} {...{ title }} />
        </div>
      )}
      {...{ url, poster, preview, aspectRatio }}
    />
  )
}

function DefaultPlayerContainer({ children }) {
  return <div className={styles.componentDefaultPlayerContainer} {...{ children }} />
}

function WavemakersPlayerContainer({ children }) {
  return <div className={styles.componentWavemakersPlayerContainer} {...{ children }} />
}

function VideoBase({
  url,
  title,
  poster,
  PlayButtonAndTitle,
  PlayerContainer,
  overlay = true,
  aspectRatio = 16 / 9,
  preview = undefined,
  renderAdditionalContent = undefined,
  className = undefined
}) {
  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)

  const [playing, setPlaying] = React.useState(false)
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const trackProgress = useProgressTracker({ title })

  // @ts-ignore
  const texttrack = url?.match(/^.*?\?texttrack=(?<language>[a-zA-Z]{2})$/)
  const language = texttrack?.groups?.language

  return (
    <div className={cx(styles.componentBase, className)} style={{ '--aspect-ratio': aspectRatio }}>
      {!hasInteracted && (
        <>
          <PlayButtonAndTitle
            onClick={() => setPlaying(!playing)}
            layoutClassName={styles.playButtonLayout}
            {...{ title, playing }}
          />
          {renderAdditionalContent && renderAdditionalContent({ playing, className: styles.additionalContent })}
          {Boolean(preview && isMounted) && <PreviewPlayer url={preview} layoutClassName={styles.previewPlayerLayout} />}
          {poster && <ImageCover image={poster} layoutClassName={styles.imageLayout} {...{ aspectRatio }} />}
          {overlay && <div className={styles.overlay} />}
        </>
      )}

      <PlayerContainer>
        {isMounted && (
          <ReactPlayer
            controls
            width="100%"
            height="100%"
            onPlay={handlePlay}
            onPause={handlePause}
            ref={videoRef}
            config={playerConfig}
            onReady={() => language && videoRef.current?.getInternalPlayer().enableTextTrack(language)}
            onProgress={({ played }) => trackProgress(played)}
            // eslint-disable-next-line @kaliber/layout-class-name
            className={styles.player}
            {...{ url, playing }}
          />
        )}
      </PlayerContainer>
    </div>
  )

  function handlePlay() {
    setPlaying(true)
    setHasInteracted(true)
    handleVideoTracking('video started')
  }

  function handlePause() {
    setPlaying(false)
    handleVideoTracking('video paused')
  }

  function handleVideoTracking(action) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackVideoMetadata({
      category: 'shared-video',
      type: 'play',
      action,
      video: getVideoMetaDataProps({ title, duration, progress })
    })
  }
}

function PreviewPlayer({ url, layoutClassName }) {
  return (
    <div className={cx(layoutClassName)}>
      <ReactPlayer loop muted playing playsinline width="100%" height="100%" {...{ url }} />
    </div>
  )
}
