import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Card.css'

import arrowIcon from '/images/icons/arrow.raw.svg'

export function Card({ children, href, dataX, dataXContext = undefined, dataStyleContext = 'light' }) {
  return <CardBase {...{ children, href, dataX, dataXContext, dataStyleContext }} />
}

export function CardWithPermanentArrowIcon({ children, href, dataX, dataXContext = undefined, dataStyleContext = 'light' }) {
  return <CardBase withPermanentIcon {...{ children, href, dataX, dataXContext, dataStyleContext }} />
}

function CardBase({ children, href, dataX, dataXContext, withPermanentIcon = false, dataStyleContext = 'light' }) {
  return (
    <a
      data-x={dataX}
      data-x-context={dataXContext}
      data-style-context={dataStyleContext}
      className={cx(styles.componentBase, withPermanentIcon && styles.withPermanentIcon)}
      {...{ href }}
    >
      <div className={styles.content}>
        {children}
      </div>

      <div className={styles.icon}>
        <Icon icon={arrowIcon} />
      </div>
    </a>
  )
}
